
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function searchPageRT () {
    return _createElement('div', { 'className': 'clear-self clear-self cm_SearchPage' }, [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2640'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current Filters'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_rdrt-btn button button--primary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        clear all\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, '\n      ', this.value.replace(/^u/, 'U'), ' \n      ', this.field === 'Color' || this.field === 'Accent Color Shades' ? _createElement('div', {
                                                'className': 'cm_color_box cm_color_box-' + this.value.replace('Shades of ', '').toLowerCase(),
                                                'key': '144'
                                            }, '\n        ', this.value === 'Multi Colored' ? '\uD83C\uDFF3️‍\uD83C\uDF08' : '', '\n      ') : null, '\n       \n      ', _createElement('span', { 'className': 'cm_hits' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, '\n      ', this.value.replace(/^u/, 'U'), ' \n      ', this.field === 'Color' || this.field === 'Accent Color Shades' ? _createElement('div', {
                                                'className': 'cm_color_box cm_color_box-' + this.value.replace('Shades of ', '').toLowerCase(),
                                                'key': '144'
                                            }, '\n        ', this.value === 'Multi Colored' ? '\uD83C\uDFF3️‍\uD83C\uDF08' : '', '\n      ') : null, '\n       \n      ', _createElement('span', { 'className': 'cm_hits' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv' + (this.isSelected ? ' selected' : '') }, !this.isCustomRange ? [
                                                '\n      ',
                                                this.value,
                                                '\n      ',
                                                _createElement('span', {
                                                    'className': 'cm_hits',
                                                    'key': '861'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [
                                                this.finite ? [
                                                    this.range[0],
                                                    ' - ',
                                                    this.range[1]
                                                ] : null,
                                                this.toPosInf ? [
                                                    this.range[0],
                                                    ' and more'
                                                ] : null,
                                                this.toNegInf ? [
                                                    this.range[1],
                                                    ' and less'
                                                ] : null
                                            ] : null);
                                        }, { count: undefined })];
                                }
                                return this.field !== 'room' ? _createElement('div', {
                                    'className': 'facetholder' + (this.field === 'Collection' ? ' cm_field-collection' : ''),
                                    'key': '3'
                                }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, this.name, ' \n    ', this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-plus-minus',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 448 512',
                                        'key': '2440'
                                    }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-plus-minus',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 448 512',
                                        'key': '6480'
                                    }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                                    'className': 'facetbody',
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '978'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '1064'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input  cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1384'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '15531'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv show-all',
                                    'key': '3838'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more'
                                }, _createElement('div', { 'className': 'cm_plus-minus' }, this.moreValuesToShow ? '+' : '–'), this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null), this.field === 'Size' ? _createElement('div', {
                                    'className': 'cm_rdrt-btn',
                                    'data-cm-role': 'next-case',
                                    'key': '4229'
                                }, 'Search specific sizes') : null) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '4360'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues3.bind(this))
                                ]), [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min price',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, 'to'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max price',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('div', {
                                            'className': 'cm_rdrt-btn',
                                            'onClick': this.setCustomRange
                                        }, 'Apply'));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_thicknessFacet',
                                    'key': '6114'
                                }, !this.isNullRange ? _createElement('div', {
                                    'className': 'facetsliderinfo',
                                    'key': '6206'
                                }, '\n  Selected: ', this.selectedRange[0], '" to ', this.selectedRange[1], '"\n') : null, this.isNullRange ? _createElement('div', {
                                    'className': 'facetsliderinfo',
                                    'key': '6344'
                                }, 'Selected: ', this.selectedRange[0], '"') : null, '\n', this.slider, '\n') : null, this.template === 'sizeFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_sizeFacet',
                                    'key': '6471'
                                }, [this.WidthFacet(function () {
                                        return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'subsize' }, _createElement('span', { 'className': 'subsize-title' }, this.field, ':'), !this.isNullRange ? _createElement('span', { 'key': '102' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '193' }, this.selectedRange[0]) : null, '\n  ', this.slider, '\n'));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], [this.LengthFacet(function () {
                                        return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'subsize' }, _createElement('span', { 'className': 'subsize-title' }, this.field, ':'), !this.isNullRange ? _createElement('span', { 'key': '103' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '194' }, this.selectedRange[0]) : null, '\n  ', this.slider, '\n'));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], _createElement('div', {
                                    'className': 'cm_rdrt-btn',
                                    'data-cm-role': 'next-case'
                                }, 'Search popular sizes')) : null)) : null;
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.SearchBoxDialogButtonResults(function () {
            return _createElement('div', { 'className': 'cm_SearchBoxDialogButtonResults' }, _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBox(function () {
                    return _createElement('div', { 'className': 'cm_searchBox' }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
                        [this.searchInput(function () {
                                return _createElement('div', { 'className': 'search-input cm_searchInput' }, 'ENTER SEARCH by BRAND, STYLE, KEYWORD, AND MORE\u2026');
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })],
                        _createElement('span', {
                            'className': 'cm_search-box_clear-container',
                            'key': '812'
                        }, this.inputNotEmpty ? _createElement('span', {
                            'className': 'cm_search-box_clear',
                            'onClick': this.clearInput,
                            'key': '430'
                        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
                        _createElement('button', {
                            'type': 'button',
                            'title': 'Search',
                            'aria-label': 'search button',
                            'className': 'cm_search-box_submit cm_hide',
                            'data-cm-role': 'add-query',
                            'key': '814'
                        }, [_createElement('svg', {
                                'viewBox': '0 0 1024 1024',
                                'className': 'icon icon-search',
                                'id': 'icon-search',
                                'key': '7430'
                            }, _createElement('path', {
                                'className': 'path1',
                                'd': 'M966.070 981.101l-304.302-331.965c68.573-71.754 106.232-165.549 106.232-265.136 0-102.57-39.942-199-112.47-271.53s-168.96-112.47-271.53-112.47-199 39.942-271.53 112.47-112.47 168.96-112.47 271.53 39.942 199.002 112.47 271.53 168.96 112.47 271.53 112.47c88.362 0 172.152-29.667 240.043-84.248l304.285 331.947c5.050 5.507 11.954 8.301 18.878 8.301 6.179 0 12.378-2.226 17.293-6.728 10.421-9.555 11.126-25.749 1.571-36.171zM51.2 384c0-183.506 149.294-332.8 332.8-332.8s332.8 149.294 332.8 332.8-149.294 332.8-332.8 332.8-332.8-149.294-332.8-332.8z'
                            }))])
                    ]), [this.dropdown(function () {
                            function repeatSections1(sections, sectionsIndex) {
                                return [sections(function () {
                                        function repeatItems1(items, itemsIndex) {
                                            return [items(function () {
                                                    function mergeProps_text(inline, external) {
                                                        var res = Object.assign({}, inline, external);
                                                        if (inline.hasOwnProperty('style')) {
                                                            res.style = _.defaults(res.style, inline.style);
                                                        }
                                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                            res.className = external.className + ' ' + inline.className;
                                                        }
                                                        return res;
                                                    }
                                                    function onError1_facetValue(e) {
                                                        e.target.classList.add('cm_hide');
                                                    }
                                                    function mergeProps_facetValue(inline, external) {
                                                        var res = Object.assign({}, inline, external);
                                                        if (inline.hasOwnProperty('style')) {
                                                            res.style = _.defaults(res.style, inline.style);
                                                        }
                                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                            res.className = external.className + ' ' + inline.className;
                                                        }
                                                        return res;
                                                    }
                                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                        'src': this.imageOrDefault(this.resizeImage(this.image)),
                                                        'alt': this.removeHTML(this.title),
                                                        'onError': this.onImageError
                                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('div', { 'className': 'title' }, _createElement('h2', {}, _createElement('span', {}, this.Manufacturer), _createElement('span', {}, this.Collection), _createElement('span', {}, this.title_sku), _createElement('span', {}, this.ColorType))), _createElement('div', { 'className': 'price' }, '\n    From\n    ', this.on_sale ? _createElement('del', { 'key': '463' }, this.formatPrice(this.compare_at_price)) : null, '\n    ', this.formatPrice(this.min_price), '\n  '))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                                        'className': 'image-block',
                                                        'key': '35'
                                                    }, _createElement('img', {
                                                        'src': this.imageUrl,
                                                        'alt': 'Image of ' + this.facetValue,
                                                        'onError': onError1_facetValue.bind(this)
                                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                        'className': 'hit-count',
                                                        'key': '335'
                                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                                }, { count: undefined })];
                                        }
                                        return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                                            'className': 'section-title-div',
                                            'key': '94'
                                        }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                                            'div',
                                            { 'className': 'cmRepeater_items' },
                                            _map(this.items, repeatItems1.bind(this))
                                        ]));
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_sections' },
                                _map(this.sections, repeatSections1.bind(this))
                            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'Convermax Advanced Search trial period has expired'), '\n  Product information including pricing and colors is outdated. This store runs on a limited trial version of\n  Convermax Advanced Search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4930' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7000'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10300'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1563'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1977'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2231'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2549'
            }, _createElement('div', { 'className': 'message-header' }, '\n  Your search\n  ', this.query ? _createElement(_Fragment, { 'key': '2672' }, 'for ', _createElement('span', { 'className': 'query' }, this.query)) : null, '\n  returned no results.\n'), _createElement('div', { 'className': 'message-body' }, 'You may ', _createElement('a', { 'onClick': this.goBack }, 'go back'), ' and search for something else.'), _createElement('div', { 'className': 'contact-container' }, _createElement('div', { 'className': 'contact' }, _createElement('div', { 'className': 'header' }, 'call us:'), _createElement('a', { 'href': 'tel:800-562-9644' }, '800-562-9644')), _createElement('div', { 'className': 'contact' }, _createElement('div', { 'className': 'header' }, 'email us:'), _createElement('a', { 'href': 'mailto:online@rugsdoneright.com' }, 'online@rugsdoneright.com')))) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3223'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3442'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3686'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '38370' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '39240' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '40070' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '38024' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, _createElement('div', {}, 'You may adjust color sliders to change results.'));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button button--primary cm_dialogButton' }, '\n  filter options \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_tune-icon',
                                    'viewBox': '0 0 56 56',
                                    'key': '750'
                                }, _createElement('path', { 'd': 'm41.08 48h-39.08c-0.553 0-1 0.448-1 1s0.447 1 1 1h39.08c0.488 3.387 3.401 6 6.92 6 3.859 0 7-3.14 7-7s-3.141-7-7-7c-3.519 0-6.432 2.613-6.92 6z' }), _createElement('path', { 'd': 'm20.695 27h-18.695c-0.553 0-1 0.448-1 1s0.447 1 1 1h18.632c0.396 3.602 3.456 6.414 7.161 6.414s6.765-2.812 7.161-6.414h19.046c0.553 0 1-0.448 1-1s-0.447-1-1-1h-19.109c-0.577-3.4-3.536-6-7.098-6s-6.521 2.6-7.098 6z' }), _createElement('path', { 'd': 'm8 0c-3.859 0-7 3.14-7 7s3.141 7 7 7c3.519 0 6.432-2.613 6.92-6h39.08c0.553 0 1-0.448 1-1s-0.447-1-1-1h-39.08c-0.488-3.387-3.401-6-6.92-6z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_tune-icon',
                                    'viewBox': '0 0 56 56',
                                    'key': '7330'
                                }, _createElement('path', { 'd': 'm8 14c3.519 0 6.432-2.614 6.92-6h39.08c0.553 0 1-0.447 1-1s-0.447-1-1-1h-39.08c-0.488-3.386-3.401-6-6.92-6-3.86 0-7 3.141-7 7s3.14 7 7 7zm0-12c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5z' }), _createElement('path', { 'd': 'm48 42c-3.519 0-6.432 2.614-6.92 6h-39.08c-0.552 0-1 0.447-1 1s0.448 1 1 1h39.08c0.488 3.386 3.401 6 6.92 6 3.859 0 7-3.141 7-7s-3.141-7-7-7zm0 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z' }), _createElement('path', { 'd': 'm54 27h-18.632c-0.396-3.602-3.455-6.414-7.161-6.414s-6.765 2.813-7.161 6.414h-19.046c-0.552 0-1 0.447-1 1s0.448 1 1 1h19.109c0.577 3.4 3.536 6 7.098 6s6.52-2.6 7.097-6h18.696c0.553 0 1-0.447 1-1s-0.447-1-1-1zm-25.793 6c-2.871 0-5.207-2.336-5.207-5.207s2.336-5.207 5.207-5.207 5.207 2.336 5.207 5.207-2.336 5.207-5.207 5.207z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', { 'className': 'cm_mobile-hide' }, 'showing ', this.pageFrom, ' – ', this.pageTo, ' of'), _createElement('span', {}, ' ', this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort cm_sort-container' }, _createElement('span', { 'className': 'sort-label' }, 'Sort'), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function repeatQvVar1(qvVar, qvVarIndex) {
                            return _createElement('tr', { 'key': `${ qvVar.size }_${ qvVar.shape }` }, _createElement('td', {}, qvVar.size), _createElement('td', { 'className': 'shape' }, qvVar.shape), _createElement('td', {}, this.formatPrice(qvVar.price)));
                        }
                        return _createElement('div', { 'className': 'cm_item' }, _createElement('div', { 'className': 'img-block' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                            'className': this.image2 ? 'image-one' : '',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError
                        }), this.image2 ? _createElement('img', {
                            'className': 'image-two',
                            'src': this.imageOrDefault(this.resizeImage(this.image2)),
                            'alt': this.removeHTML(this.title),
                            'onError': e => this.onImageError(e, 'image2'),
                            'key': '279'
                        }) : null), '\n    ', this.comparerCheckBox, '\n  '), _createElement('div', { 'className': 'on-sale' }, this.on_sale ? [
                            '\n      save ',
                            Math.round((this.compare_at_price - this.min_price) / this.compare_at_price * 100),
                            '%\n    '
                        ] : null), _createElement('a', {
                            'href': this.url,
                            'className': 'item-title'
                        }, _createElement('h2', {}, _createElement('span', { dangerouslySetInnerHTML: { __html: this.Manufacturer ? this.Manufacturer + ' ' : '' } }), _createElement('span', { dangerouslySetInnerHTML: { __html: this.Collection ? this.Collection + ' ' : '' } }), _createElement('span', { dangerouslySetInnerHTML: { __html: this.title_sku ? this.title_sku + ' ' : '' } }), _createElement('span', { dangerouslySetInnerHTML: { __html: this.ColorType ? this.ColorType : '' } }))), _createElement('div', { 'className': 'item-price' }, '\n    From\n    ', this.on_sale ? _createElement('del', { 'key': '1334' }, this.formatPrice(this.compare_at_price)) : null, '\n    ', this.formatPrice(this.min_price), _createElement('br', {}), this.qvVariants ? _createElement('div', {
                            'className': 'qv-container',
                            'key': '1456'
                        }, '\n      Quick View +\n      ', _createElement('div', { 'className': 'quick-view' }, _createElement('div', { 'className': 'overflow-container' }, _createElement('table', {}, _createElement.apply(this, [
                            'tbody',
                            {},
                            _createElement('tr', {}, _createElement('th', {}, 'Size'), _createElement('th', {}, 'Shape'), _createElement('th', {}, 'Price')),
                            _map(this.qvVariants, repeatQvVar1.bind(this))
                        ]))))) : null));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'Convermax Advanced Search trial period has expired'), '\n  Product information including pricing and colors is outdated. This store runs on a limited trial version of\n  Convermax Advanced Search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4930' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7000'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10300'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1563'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1977'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2231'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2549'
                    }, _createElement('div', { 'className': 'message-header' }, '\n  Your search\n  ', this.query ? _createElement(_Fragment, { 'key': '2672' }, 'for ', _createElement('span', { 'className': 'query' }, this.query)) : null, '\n  returned no results.\n'), _createElement('div', { 'className': 'message-body' }, 'You may ', _createElement('a', { 'onClick': this.goBack }, 'go back'), ' and search for something else.'), _createElement('div', { 'className': 'contact-container' }, _createElement('div', { 'className': 'contact' }, _createElement('div', { 'className': 'header' }, 'call us:'), _createElement('a', { 'href': 'tel:800-562-9644' }, '800-562-9644')), _createElement('div', { 'className': 'contact' }, _createElement('div', { 'className': 'header' }, 'email us:'), _createElement('a', { 'href': 'mailto:online@rugsdoneright.com' }, 'online@rugsdoneright.com')))) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3223'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3442'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3686'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '38370' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '39240' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '40070' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '38024' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse button button--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        show more\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.scrollController(function () {
                    return _createElement('div', { 'className': 'cm_scrollController' }, _createElement('div', {
                        'className': 'scroll-top',
                        'onClick': this.scrollToTop
                    }, _createElement('svg', {
                        'className': 'cm_icon cm_icon-arrow up',
                        'height': '20px',
                        'role': 'img',
                        'viewBox': '0 0 512 512',
                        'aria-hidden': 'true'
                    }, _createElement('path', { 'd': 'M189.3,128.4L89,233.4c-6,5.8-9,13.7-9,22.4c0,8.7,3,16.5,9,22.4l100.3,105.4c11.9,12.5,31.3,12.5,43.2,0c11.9-12.5,11.9-32.7,0-45.2L184.4,288h217c16.9,0,30.6-14.3,30.6-32c0-17.7-13.7-32-30.6-32h-217l48.2-50.4c11.9-12.5,11.9-32.7,0-45.2C220.6,115.9,201.3,115.9,189.3,128.4z' }))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets","cm:FacetPanel","cm:searchInput","cm:browseAllButton","cm:dropdown","cm:searchBox","cm:SearchBoxDialogButtonResults","cm:message","cm:customMessage","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:scrollController","cm:SearchResult"]