/* eslint-disable camelcase */
const mapFields = {
  // filter fields
  ss_length: 'Length',
  ss_room: 'room',
  ss_sizes: 'Size',
  ss_width: 'Width',
  tags_collection: 'Collection',
  tags_color: '_Color',
  tags_country_of_origin: 'Country of Origin',
  tags_item_type: 'Item Type',
  tags_manufacturer: 'Manufacturer',
  tags_material: 'Material',
  tags_shape: 'Shape',
  tags_style: 'Style',
  tags_weave: 'Weave',
  variant_price: 'price',
  // sort fields
  ga_unique_purchases: 'order_count',
  ss_days_since_published: 'published_at:desc',
  ss_price: 'price',
  title: 'title',
};
const mapColors = {
  beige: 'f5f5dc',
  brown: '4f2907',
  blue: '1f5e9e',
  green: '248a0f',
  grey: '808080',
  black: '101010',
  ivory: 'fffff0',
  orange: 'fa760a',
  pink: 'eb6fa4',
  purple: '67357e',
  red: 'c00718',
  yellow: 'ffd246',
};
export default {
  parsingNeeded(pageType) {
    const {
      location: { search, hash },
    } = window;
    return (
      ['filter:', 'sort:'].some((v) => hash.includes(v)) || (pageType !== 'brand' && /q=[^#]/.test(search))
    );
  },
  parse(pageType) {
    const searchMap = location.search
      .substring(1)
      .split('&')
      .reduce((map, entry) => {
        const [key, value = ''] = entry.split('=');
        if (key === 'q' && pageType !== 'brand') {
          map.query = value;
        }
        return map;
      }, {});
    const hashMap = location.hash
      .substring(2)
      .split('/')
      .reduce(
        (map, entry) => {
          const split = entry.split(':');
          switch (split.shift()) {
            case 'filter': {
              const field = mapFields[split.shift()];
              const term = this.customDecode(field, split);
              map.selection.push({ field, term });
              break;
            }
            case 'sort':
              if (map.sort) {
                map.sort += ',';
              }
              map.sort += `${mapFields[split.shift()]}${split.shift() === 'desc' ? ':desc' : ''}`;
              break;
          }
          return map;
        },
        { selection: [], sort: '' },
      );
    return { ...searchMap, ...hashMap, extra: { map_values: true } };
  },
  customDecode(field, values) {
    switch (field) {
      case 'Width': // fallthrough
      case 'Length':
        return `${values[0]} TO ${values[1]}`;
      case 'price': {
        return `${values[0]} TO ${values[1]}`;
      }
      case '_Color':
        for (const name in mapColors) {
          if (values[0].toLowerCase().includes(name)) {
            return `${mapColors[name]} 100`;
          }
        }
        return '808080 100';
      default:
        return decodeURIComponent(values[0].replace(/\$25/g, '%'));
    }
  },
};
