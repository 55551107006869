import customUriParser from './uriParser.js';

function getInitCase() {
  return /([?/])(Width|Length)\//.test(window.location.search) ? 1 : 0;
}

function InitFunc() {
  const { document, Convermax, __CONVERMAX__: { product } = {} } = window;

  if (!product) {
    return;
  }

  Convermax.product = product;
  if (product.multipleImages) {
    document.body.classList.add('cm_multiple-images-product');
  }
}

function responseHandler(resp) {
  resp.Items.forEach((item) => {
    item.qvVariants = item.qvVariants?.map(JSON.parse);
  });
  return resp;
}

function updateCallback() {
  [...window.document.querySelectorAll('.qv-container')].forEach((container) => {
    const qv = container.querySelector('.quick-view');
    if (qv && qv.offsetParent) {
      const overflow = container.offsetLeft + qv.offsetWidth - container.offsetParent.offsetWidth;
      if (overflow > 0) {
        qv.style.left = `${-overflow}px`;
      }
    }
  });
}

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  noResultsRedirectTimeout: 5000,
  product: {
    searchResultsImageHeight: 350,
    noImageSrc: 'https://client.convermax.com/static/img/image-coming-soon.png',
  },
  scrollDetection: {
    threshold: 1000,
    higher: 'cm_scroll-controller-hidden',
  },
  InitFunc,
  FacetsCollapsedDefault: true,
  stickyHeaderSelector: '.sellify-countdown-timer-holder',
  maxCountByField: {
    Collection: 100,
  },
  SearchRequestDefaults: {
    pageSize: 48,
  },
  responseHandler,
  customUriParser,
  getLocalPreselection: (pageType, defaults) => {
    const result = defaults.getter(pageType);
    if (pageType === 'brand') {
      return result.map((v) => ({ ...v, field: 'Manufacturer' }));
    }
    return result;
  },
  getIgnoreDefaultSearchParamNames: (pageType) => {
    const baseIgnoreNames = ['view'];
    if (pageType === 'brand') {
      return baseIgnoreNames;
    }
    return [...baseIgnoreNames, 'q'];
  },
  facets: {
    rangedFacet: [{ fields: ['Thickness'], name: 'thicknessFacet', step: '1/100' }],
    simpleFacet: { fields: ['Material'], childFields: ['Fiber'] },
    switchFacet: {
      fields: ['Size'],
      childFields: ['Width', 'Length'],
      singleFacet: true,
      step: 'imperial',
      getInitCase,
      cases: [{ type: 'simpleFacet' }, { type: 'sizeFacet' }],
    },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_SearchPage, [data-template="collection"] main>.padding-bottom--up-4>.theme-container',
      template: 'colorSearch/searchPage',
    },
    {
      name: 'SearchBox',
      location: '#searchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButtonResults',
      type: 'SearchBoxDialogButton',
    },
    {
      name: 'RugSearchPanel',
      type: 'RequestPanel',
      location: {
        replace: '.theme-homepage-hero-search',
        class: 'cm_req-panel-container',
      },
      template: 'SearchPanelDropdowns',
      needInitRequest: true,
      isAutoRedirectDisabled: true,
      selectFields: ['room', 'Size', 'Color', 'Style'],
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'order_count:desc': 'Best Selling',
        'published_at:desc': 'Newest',
        'title': 'Alphabetically, A-Z',
        'title:desc': 'Alphabetically, Z-A',
        'price': 'Price, low to high',
        'price:desc': 'Price, high to low',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'Comparer',
      template: 'Comparer',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
