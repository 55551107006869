export default {
  // search
  // - localization
  CURRENT_SEARCH: 'Current Filters',
  START_OVER: 'clear all',
  FILTERS_TEXT: 'filter options',
  LOAD_MORE_TEXT: 'show more',
  SEARCH_BOX_PLACEHOLDER: 'ENTER SEARCH by BRAND, STYLE, KEYWORD, AND MORE…',
  SORT_BY: 'Sort',
  TO_TEXT: 'to',

  // - classes
  FACET_BUTTON_CLASS: 'cm_rdrt-btn',
  SEARCH_BOX_BUTTON_CLASS: 'cm_hide',
  SEARCH_BOX_INPUT_CLASS: 'search-input',
  SEARCH_PAGE_CLASS: 'clear-self',
  SORT_CONTAINER_CLASS: 'cm_sort-container',

  // - other
  CHIPS_INCH_FIELDS: "['Thickness']",
  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed" template="icons/plus" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/minus" />`,
  FILTERS_ICON: `
    <rt-virtual rt-if="this.withSelection" template="icons/tune-filled" />
    <rt-virtual rt-if="!this.withSelection" template="icons/tune-empty" />`,

  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--primary',
};
