import { SearchPage } from 'Components'
import TemplatecolorSearchsearchPage from 'Stores/_default-store/Templates/colorSearch/searchPage.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import { RequestPanel } from 'Components'
import TemplateSearchPanelDropdowns from 'Stores/rugsdoneright/Templates/SearchPanelDropdowns.rt'
import { FacetPanel } from 'Components'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { Comparer } from 'Components'
import TemplateComparer from 'Stores/rugsdoneright/Templates/Comparer.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchBox,
SearchBoxDialogButton,
RequestPanel,
FacetPanel,
SearchHeader,
SearchResult,
Comparer,
FacetDialog,
SearchBoxDialog
} 
export const tplMap = {
  'colorSearch/searchPage': TemplatecolorSearchsearchPage,
'SearchBox': TemplateSearchBox,
'SearchPanelDropdowns': TemplateSearchPanelDropdowns,
'Comparer': TemplateComparer,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};