
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function ComparerRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatQvVar1(qvVar, qvVarIndex) {
                    return _createElement('tr', { 'key': `${ qvVar.size }_${ qvVar.shape }` }, _createElement('td', {}, qvVar.size), _createElement('td', { 'className': 'shape' }, qvVar.shape), _createElement('td', {}, this.formatPrice(qvVar.price)));
                }
                return _createElement('div', { 'className': 'cm_item' }, _createElement('div', { 'className': 'img-block' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': this.image2 ? 'image-one' : '',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'image-two',
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '279'
                }) : null), '\n    ', this.comparerCheckBox, '\n  '), _createElement('div', { 'className': 'on-sale' }, this.on_sale ? [
                    '\n      save ',
                    Math.round((this.compare_at_price - this.min_price) / this.compare_at_price * 100),
                    '%\n    '
                ] : null), _createElement('a', {
                    'href': this.url,
                    'className': 'item-title'
                }, _createElement('h2', {}, _createElement('span', { dangerouslySetInnerHTML: { __html: this.Manufacturer ? this.Manufacturer + ' ' : '' } }), _createElement('span', { dangerouslySetInnerHTML: { __html: this.Collection ? this.Collection + ' ' : '' } }), _createElement('span', { dangerouslySetInnerHTML: { __html: this.title_sku ? this.title_sku + ' ' : '' } }), _createElement('span', { dangerouslySetInnerHTML: { __html: this.ColorType ? this.ColorType : '' } }))), _createElement('div', { 'className': 'item-price' }, '\n    From\n    ', this.on_sale ? _createElement('del', { 'key': '1334' }, this.formatPrice(this.compare_at_price)) : null, '\n    ', this.formatPrice(this.min_price), _createElement('br', {}), this.qvVariants ? _createElement('div', {
                    'className': 'qv-container',
                    'key': '1456'
                }, '\n      Quick View +\n      ', _createElement('div', { 'className': 'quick-view' }, _createElement('div', { 'className': 'overflow-container' }, _createElement('table', {}, _createElement.apply(this, [
                    'tbody',
                    {},
                    _createElement('tr', {}, _createElement('th', {}, 'Size'), _createElement('th', {}, 'Shape'), _createElement('th', {}, 'Price')),
                    _map(this.qvVariants, repeatQvVar1.bind(this))
                ]))))) : null));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_comparer' }, _createElement('div', {
        'className': 'cm_title',
        'data-cm-role': 'toggle-comparer'
    }, '\n    compare selections (', this.items.length, ')\n    ', _createElement('span', {
        'className': 'clear-all',
        'data-cm-role': 'clear-comparer'
    }, 'clear all'), this.isCollapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle up',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '2060'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle down',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '5250'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement.apply(this, [
        'div',
        {
            'style': { height: '0' },
            'className': 'cmRepeater_items'
        },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []