import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchItem from 'Components/searchItem/index.jsx';
import { clearCompared } from 'Core/actions/localStorage.js';
import { useCollapsible } from 'Core/hooks/index.js';
import { comparedItemsSelector } from 'Core/selectors/localStorage.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';

const openHeight = 540;

export default function Comparer({ template }) {
  const dispatch = useDispatch();

  const rootRef = useRef();
  const [collapse, toggleComparer] = useCollapsible(rootRef, '.cmRepeater_items', true, { openHeight });

  const itemsData = useSelector(comparedItemsSelector);
  const isEmpty = !itemsData.length;

  useEffect(() => {
    if (isEmpty && !collapse) {
      toggleComparer();
    }
  });

  if (isEmpty) {
    return null;
  }

  const items = itemsData.repeaterComponents(SearchItem);

  const clearComparer = () => dispatch(clearCompared());

  const onClick = simpleHandler({ toggleComparer, clearComparer });

  const component = template.call({ items, collapse });
  return cloneSafe(component, rootRef, { onClick });
}
